@import url(./App.css);

header {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

h3 {
    margin-bottom: 10px;
}

hr {
    border: 1px solid var(--accent-color);
    margin-bottom: 10px;
}